import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TrackingTable from "./TrackingsTable"

function TrackingsIndex({ trackings }) {

    return (
        <>
            <Link to="/trackings/new">Crear registro</Link>

            <TrackingTable trackings={trackings} />
        </>
    );
}

TrackingsIndex.propTypes = {
    trackings: PropTypes.array,
};

export default TrackingsIndex;
import Box from "@mui/material/Box";
import React from "react";
import PropTypes from "prop-types";
import SearchBar from "../../components/SearchBar";
import { Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import theme from "../../../packs/mui_theme";
import Filters from "../../components/Filters";
import ModalWithButton from "../../components/ModalWithButton";
import OperatorForm from "./OperatorForm";
import OperatorsTable from "./OperatorsTable";
import { OperatorIcon, StateIcon, ClientIcon } from "../../components/Icons";

function OperatorsIndex({ operators }) {
    const filters = [
        { label: "Cargo", options: ["vacio"], icon: OperatorIcon },
        { label: "Estado", options: ["En contrato", "Disponible"], icon: StateIcon },
        { label: "Interno/Externo", options: ["vacio"], icon: ClientIcon },
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: "100vh" }}>
                <Grid item xs={12} sx={{ paddingX: 4 }} md={12} component={Paper} elevation={6} square>
                    <Box sx={{ backgroundColor: "#F3F4F4", borderRadius: "10px" }}>
                        <Grid container
                            spacing={2}
                            columns={16}
                            sx={{
                                p: "10px",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="headlineLarge"
                                color="#414648"
                                sx={{
                                    fontWeight: "700",
                                }}
                            >
                                Operarios
                            </Typography>

                            <ModalWithButton
                                buttonMessage="Agregar operario"
                                modalTitle="Agregar operario"
                                modalIcon={OperatorIcon}
                                modalContent={OperatorForm}
                                formId="operator-form"
                                buttonTitle="Agregar operario"
                            />
                        </Grid>

                        <Grid container
                            sx={{
                                p: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <SearchBar
                                placeholderText="Buscar operarios"
                            />

                            <Filters
                               filters={filters}
                            />
                        </Grid>

                        <OperatorsTable operators={operators} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

OperatorsIndex.propTypes = {
    operators: PropTypes.array,
};

export default OperatorsIndex;
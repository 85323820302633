import React from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';

function SignOutButton() {

    const handleLogout = () => {
        fetch("/users/sign_out", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            }
        })
            .then(response => {
                if (response.ok) {
                    window.location.href = "/users/sign_in";
                } else {
                    throw new Error("Logout failed");
                }
            })
            .catch(error => {
                console.error("Error during logout:", error);
            });
    };

    return (
        <Button
            variant="outlined"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
        >
            Salir
        </Button>
    );
}

export default SignOutButton;
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import theme from "../../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

function TrackingsTable({ trackings }) {
    const [trackingsList, setTrackingList] = useState(trackings || []);

    useEffect(() => {
        if (trackingsList.length === 0) {
            fetch("/api/trackings", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    setTrackingList(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [trackingsList]);

    return (
        <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Maquinaria</TableCell>
                            <TableCell align="center">Fecha de Inicio</TableCell>
                            <TableCell align="center">Fecha Final</TableCell>
                            <TableCell align="center">ACPM</TableCell>
                            <TableCell align="center">Odometro Inicial</TableCell>
                            <TableCell align="center">Horometro Inicial</TableCell>
                            <TableCell align="center">Horometro Final</TableCell>
                            <TableCell align="center">Eficiencia del ACPM</TableCell>
                            <TableCell align="center">Horas Trabajadas</TableCell>
                            <TableCell align="center">Horas en Reposo</TableCell>
                            <TableCell align="center">Horas Facturadas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trackingsList.map((machine) => (
                            <React.Fragment key={machine.id}>
                                {machine.trackings.map((tracking, index) => (
                                    <TableRow key={tracking.id}>
                                        {index === 0 && (
                                            <TableCell rowSpan={machine.trackings.length} align="center">
                                                {machine.machine_name} ({machine.operator_name})<br /> {machine.agreement_location}
                                            </TableCell>
                                        )}
                                        <TableCell component="th" scope="row">
                                            {tracking.start_date}
                                        </TableCell>
                                        <TableCell align="center">{tracking.end_date}</TableCell>
                                        <TableCell align="center">{tracking.fuel}</TableCell>
                                        <TableCell align="center">{tracking.initial_odometer}</TableCell>
                                        <TableCell align="center">{tracking.initial_hourmeter}</TableCell>
                                        <TableCell align="center">{tracking.final_hourmeter}</TableCell>
                                        <TableCell align="center">{tracking.acpm_performance}</TableCell>
                                        <TableCell align="center">{tracking.hours_worked}</TableCell>
                                        <TableCell align="center">{tracking.standby_hours}</TableCell>
                                        <TableCell align="center">{tracking.billed_hours}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    );
}

TrackingsTable.propTypes = {
    trackings: PropTypes.array,
};

export default TrackingsTable;

import Box from "@mui/material/Box";
import React, { useState } from "react";
import theme from "../../../packs/mui_theme";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alerts from "../../components/Alerts";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const InputLabelWithStyles = ({ inputLabelText }) => {
    return (
        <InputLabel>
            <Typography variant="labelMedium"
                sx={{
                    color: "neutral.10",
                    fontWeight: "600",
                    lineHeight: "12px"
                }}
            >
                {inputLabelText}
            </Typography>
        </InputLabel>
    )
};

function OperatorForm() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        identification_number: "",
        phone_number: "",
        email: "",
        password: "",
        operator_info_attributes: {
            operator_cost: "",
            arl: "",
            eps: "",
            arl_risk_range: "",
            position: "",
            operator_type: ""
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split(".");

        if (keys.length > 1) {
            setFormData({
                ...formData,
                operator_info_attributes: {
                    ...formData.operator_info_attributes,
                    [keys[1]]: value,
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("/api/operators", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ operator: formData }),
            });

            if (response.ok) {
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                const errorData = await response.json();
                console.log("Error:", errorData);
            }
        } catch (error) {
            console.log("Network Error:", error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit} id="operator-form">
                <Box>
                    <Grid container
                        sx={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Nombre"
                            />
                            <TextField
                                id="first_name"
                                name="first_name"
                                variant="filled"
                                value={formData.first_name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Apellido"
                            />
                            <TextField
                                id="last_name"
                                name="last_name"
                                variant="filled"
                                value={formData.last_name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Tipo de documento"
                            />
                            <TextField
                                id="document_type"
                                name="document_type"
                                variant="filled"
                                value={formData.document_type}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Número de documento"
                            />
                            <TextField
                                id="identification_number"
                                name="identification_number"
                                variant="filled"
                                value={formData.identification_number}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Teléfono"
                            />
                            <TextField
                                id="phone_number"
                                name="phone_number"
                                variant="filled"
                                value={formData.phone_number}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Correo electronico"
                            />
                            <TextField
                                id="email"
                                name="email"
                                variant="filled"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Contraseña"
                            />
                            <TextField
                                id="password"
                                name="password"
                                variant="filled"
                                value={formData.password}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Tipo de operador"
                            />
                            <Select
                                id="operator_info_attributes.operator_type"
                                name="operator_info_attributes.operator_type"
                                variant="filled"
                                value={formData.operator_info_attributes.operator_type}
                                onChange={handleChange}
                                fullWidth
                            >
                                <MenuItem value="internal">Interno</MenuItem>
                                <MenuItem value="external">Externo</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Cargo"
                            />
                            <TextField
                                id="operator_info_attributes.position"
                                name="operator_info_attributes.position"
                                variant="filled"
                                value={formData.operator_info_attributes.position}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Costo"
                            />
                            <TextField
                                id="operator_info_attributes.operator_cost"
                                name="operator_info_attributes.operator_cost"
                                variant="filled"
                                value={formData.operator_info_attributes.operator_cost}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="EPS"
                            />
                            <TextField
                                id="operator_info_attributes.eps"
                                name="operator_info_attributes.eps"
                                variant="filled"
                                value={formData.operator_info_attributes.eps}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Arl"
                            />
                            <TextField
                                id="operator_info_attributes.arl"
                                name="operator_info_attributes.arl"
                                variant="filled"
                                value={formData.operator_info_attributes.arl}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="ARL - rango de riesgo"
                            />
                            <Select
                                id="operator_info_attributes.arl_risk_range"
                                name="operator_info_attributes.arl_risk_range"
                                variant="filled"
                                value={formData.operator_info_attributes.arl_risk_range}
                                onChange={handleChange}
                                fullWidth
                            >
                                <MenuItem value="one">Nivel 1</MenuItem>
                                <MenuItem value="two">Nivel 2</MenuItem>
                                <MenuItem value="three">Nivel 3</MenuItem>
                                <MenuItem value="four">Nivel 4</MenuItem>
                                <MenuItem value="five">Nivel 5</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Alerts
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message="Operador creado con éxito."
                severity="success"
            />
        </ThemeProvider>
    );
}

export default OperatorForm;
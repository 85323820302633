import Box from "@mui/material/Box";
import React from "react";
import PropTypes from "prop-types";
import SearchBar from "../../components/SearchBar";
import Filters from "../../components/Filters";
import { Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import theme from "../../../packs/mui_theme";
import ModalWithButton from "../../components/ModalWithButton";
import ClientForm from "./ClientForm";
import ClientTable from "./ClientTable";
import { ClientIcon } from "../../components/Icons";

function ClientsIndex({ clients }) {
    const filter = [
        { label: "Tipo de documento", options: ["Cedula", "NIT"], icon: ClientIcon },
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: "100vh" }}>
                <Grid item xs={12} sx={{ paddingX: 4 }} md={12} component={Paper} elevation={6} square>
                    <Box sx={{ backgroundColor: "#F3F4F4", borderRadius: "10px" }}>
                        <Grid container
                            spacing={2}
                            columns={16}
                            sx={{
                                p: "10px",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="headlineLarge"
                                color="#414648"
                                sx={{
                                    fontWeight: "700",
                                }}
                            >
                                Clientes
                            </Typography>

                            <ModalWithButton
                                buttonMessage="Agregar cliente"
                                modalTitle="Agregar cliente"
                                modalIcon={ClientIcon}
                                modalContent={ClientForm}
                                formId="client-form"
                                buttonTitle="Agregar cliente"
                            />
                        </Grid>

                        <Grid container
                            sx={{
                                p: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <SearchBar
                                placeholderText="Buscar cliente"
                            />

                            <Filters
                                filters={filter}
                            />
                        </Grid>

                        <ClientTable clients={clients} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

ClientsIndex.propTypes = {
    clients: PropTypes.array,
};

export default ClientsIndex;
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import theme from "../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DialogIconWithText({ title, icon: Icon }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ backgroundColor: "#144BE1", borderRadius: "15px" }}>
                    <Icon sx={{ color: "#FFFFFF" }} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                id="custom-modal-title"
                variant="h6"
                component="h1"
                sx={{ ml: 1 }}
                primary={
                    <Typography variant="bodyLarge" sx={{
                        color: "neutral.10",
                        fontWeight: "600",
                        lineHeight: "24px"
                    }}
                    >{title}</Typography>
                }
            />
        </>
    );
}

const ModalWithButton = ({ buttonMessage, modalTitle, modalIcon: ModalIcon, modalContent: ModalContent, formId, buttonTitle }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Button
                    onClick={handleOpen}
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{
                        mt: 3,
                        p: "12px 16px",
                        lineHeight: "14px",
                        fontWeight: "500",
                        fontSize: "14px",
                    }}
                >
                    {buttonMessage}
                </Button>
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    PaperProps={{
                        sx: {
                            width: "625px",
                            borderRadius: "12px",
                        },
                    }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <DialogIconWithText title={modalTitle} icon={ModalIcon} />
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <ModalContent />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            form={formId}
                            endIcon={<AddIcon />}
                            variant="contained"
                        >
                            {buttonTitle}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </>
    );
};

ModalWithButton.propTypes = {
    buttonMessage: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalIcon: PropTypes.elementType.isRequired,
    modalContent: PropTypes.elementType.isRequired,
    formId: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
};

export default ModalWithButton;
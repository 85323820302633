import React from "react";
import PropTypes from "prop-types";
import theme from "../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Pagination, PaginationItem, Box, Grid } from "@mui/material";

const PaginationComponent = ({ count, page, rowsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(count / rowsPerPage);

    const handlePageChange = (event, value) => {
        onPageChange(value - 1);
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main">
                <Grid item xs={12} sx={{ paddingX: 4 }} md={12}>
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "white",
                            border: "1px solid transparent",
                            borderRadius: "12px",
                            padding: "12px"
                        }}
                    >
                        <Pagination
                            count={totalPages}
                            page={page + 1}
                            onChange={handlePageChange}
                            color="primary"
                            shape="rounded"
                            variant="outlined"
                            size="large"
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{
                                        previous: (props) => (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <ArrowBackIcon {...props} />
                                                <span>Anterior</span>
                                            </Box>
                                        ),
                                        next: (props) => (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <span>Siguiente</span>
                                                <ArrowForwardIcon {...props} />
                                            </Box>
                                        )
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
};

PaginationComponent.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default PaginationComponent;
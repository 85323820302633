import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OperatorForm from "./OperatorForm";
import DynamicTable from "../../components/DynamicTable";
import OperatorsEmptyState from "../../../../assets/images/operators_empty_state.png";
import { OperatorIcon } from "../../components/Icons";

function OperatorsTable({ operators }) {
    const [operatorsList, setMachinesList] = useState(operators || []);
    const columns = [
        { label: "Nombre y apellido", field: "full_name" },
        { label: "Estado", field: "status" },
        { label: "Cargo", field: "position" },
        { label: "Tipo operario", field: "operator_type" },
        { label: "Maquina asignada", field: "" },
        { label: "Teléfono", field: "phone_number" },
    ];

    useEffect(() => {
        if (operatorsList.length === 0) {
            fetch("/api/operators", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    setMachinesList(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [operatorsList]);

    return (
        <>
            <DynamicTable columns={columns}
                data={operatorsList}
                emptyStateIcon={OperatorsEmptyState}
                emptyStatePrimaryText="Aún no has agregado operarios"
                emptyStateSecondaryText="Aquí encontrarás todos los operarios que hayas agregado."
                emptyStateButtonMessage="Agregar operario"
                emptyStateModalTitle="Agregar operario"
                emptyStateModalIcon={OperatorIcon}
                emptyStateModalContent={OperatorForm}
                emptyStateFormId="operator-form"
                emptyStateButtonTitle="Agregar operario"
            />
        </>
    );
}

OperatorsTable.propTypes = {
    operators: PropTypes.array,
};

export default OperatorsTable;
import Box from "@mui/material/Box";
import React, { useState } from "react";
import theme from "../../../packs/mui_theme";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alerts from "../../components/Alerts";

function MachineForm() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        kilometers_difference: "",
        hourmeter: "",
        acpm_average: "",
        basic_hourly_price: "",
        basic_fee: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("/machines", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ machine: formData }),
            });

            if (response.ok) {
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                const errorData = await response.json();
                console.log("Error:", errorData);
            }
        } catch (error) {
            console.log("Network Error:", error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit} id="machine-form">
                <Box>
                    <Grid container
                        sx={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}>

                        <Grid item>
                            <InputLabel>
                                <Typography variant="labelMedium"
                                    sx={{
                                        color: "neutral.10",
                                        fontWeight: "600",
                                        lineHeight: "12px"
                                    }}
                                >
                                    Nombre/referencia de la maquina
                                </Typography>
                            </InputLabel>
                            <TextField
                                id="name"
                                name="name"
                                variant="filled"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabel >
                                <Typography variant="labelMedium"
                                    sx={{
                                        color: "neutral.10",
                                        fontWeight: "600",
                                        lineHeight: "12px"
                                    }}
                                >
                                    Consumo promedio (gal/h)
                                </Typography>
                            </InputLabel>
                            <TextField
                                id="acpm_average"
                                name="acpm_average"
                                variant="filled"
                                value={formData.acpm_average}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabel>
                                <Typography variant="labelMedium"
                                    sx={{
                                        color: "neutral.10",
                                        fontWeight: "600",
                                        lineHeight: "12px"
                                    }}
                                >
                                    Horómetro inicial
                                </Typography>
                            </InputLabel>
                            <TextField
                                id="hourmeter"
                                name="hourmeter"
                                variant="filled"
                                value={formData.hourmeter}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid container sx={{ justifyContent: "space-between", }}>
                            <Grid>
                                <InputLabel>
                                    <Typography variant="labelMedium"
                                        sx={{
                                            color: "neutral.10",
                                            fontWeight: "600",
                                            lineHeight: "12px"
                                        }}
                                    >
                                        Costo/hora
                                    </Typography>
                                </InputLabel>
                                <TextField
                                    id="basic_hourly_price"
                                    name="basic_hourly_price"
                                    variant="filled"
                                    value={formData.basic_hourly_price}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid>
                                <InputLabel>
                                    <Typography variant="labelMedium"
                                        sx={{
                                            color: "neutral.10",
                                            fontWeight: "600",
                                            lineHeight: "12px"
                                        }}
                                    >
                                        Costo/hora adicional operador
                                    </Typography>
                                </InputLabel>
                                <TextField
                                    id="basic_fee"
                                    name="basic_fee"
                                    variant="filled"
                                    value={formData.basic_fee}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Alerts
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message="Máquina creada con éxito."
                severity="success"
            />
        </ThemeProvider>
    );
}

MachineForm.propTypes = {};

export default MachineForm;
import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import theme from "../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Checkbox
} from "@mui/material";
import Pagination from "../../bundles/components/Pagination";
import EmptyStateComponent from "../components/EmptyState";

function DynamicTable({
    columns, data, emptyStateIcon, emptyStatePrimaryText,
    emptyStateSecondaryText, emptyStateButtonMessage, emptyStateModalTitle,
    emptyStateModalIcon, emptyStateModalContent, emptyStateFormId, emptyStateButtonTitle
}) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((item) => item.id);
            setSelectedItems(newSelected);
            return;
        }
        setSelectedItems([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedItems.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1)
            );
        }

        setSelectedItems(newSelected);
    };

    const isSelected = (id) => selectedItems.indexOf(id) !== -1;

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    return (
        <ThemeProvider theme={theme}>
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: "12px",
                }}
            >
                {data.length === 0 ? (
                    <EmptyStateComponent
                        imageUrl={emptyStateIcon}
                        primaryText={emptyStatePrimaryText}
                        secondaryText={emptyStateSecondaryText}
                        buttonMessage={emptyStateButtonMessage}
                        modalTitle={emptyStateModalTitle}
                        modalIcon={emptyStateModalIcon}
                        modalContent={emptyStateModalContent}
                        formId={emptyStateFormId}
                        buttonTitle={emptyStateButtonTitle}
                    />
                ) : (
                    <>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={
                                                selectedItems.length > 0 &&
                                                selectedItems.length < data.length
                                            }
                                            checked={
                                                data.length > 0 &&
                                                selectedItems.length === data.length
                                            }
                                            onChange={handleSelectAllClick}
                                            inputProps={{
                                                "aria-label": "select all items",
                                            }}
                                        />
                                    </TableCell>
                                    {columns.map((column, index) => (
                                        <TableCell key={index}>{column.label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : data
                                ).map((item) => {
                                    const isItemSelected = isSelected(item.id);
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, item.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={item.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": `checkbox-list-label-${item.id}`,
                                                    }}
                                                />
                                            </TableCell>
                                            {columns.map((column, index) => (
                                                <TableCell key={index}>
                                                    {item[column.field] ? item[column.field] : "Vacio"}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={columns.length + 1} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </>
                )}
            </TableContainer>
            <Pagination
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                disabled={data === 0} // Desactivar paginador si no hay datos
            />
        </ThemeProvider>
    );
}

DynamicTable.propTypes = {
    data: PropTypes.array.isRequired,
    emptyStateIcon: PropTypes.string.isRequired,
    emptyStatePrimaryText: PropTypes.string.isRequired,
    emptyStateSecondaryText: PropTypes.string.isRequired,
    emptyStateButtonMessage: PropTypes.string.isRequired,
    emptyStateModalTitle: PropTypes.string.isRequired,
    emptyStateModalIcon: PropTypes.elementType.isRequired,
    emptyStateModalContent: PropTypes.elementType.isRequired,
    emptyStateFormId: PropTypes.string.isRequired,
    emptyStateButtonTitle: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            field: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default DynamicTable;
import React from "react";
import Box from "@mui/material/Box";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TrackingsIndex from "./bundles/Tracking/components/TrackingsIndex";
import TrackingForm from "./bundles/Tracking/components/TrackingForm";
import MachinesIndex from "./bundles/Machines/components/MachinesIndex";
import MachineForm from "./bundles/Machines/components/MachineForm";
import OperatorsIndex from "./bundles/Operators/components/OperatorsIndex";
import ClientsIndex from './bundles/Clients/components/ClientsIndex';
import SideBar from "./bundles/components/SideBar";
import PropTypes from "prop-types";

function App({ currentUser, currentUserIsAdmin, machines, machine, trackings, user, operators, clients }) {
    return (
        <Box sx={{ display: "flex" }}>
            <BrowserRouter>
                <SideBar isAdmin={currentUserIsAdmin} currentUser={currentUser} />
                <Routes>
                    <Route path="/operators" element={<OperatorsIndex operators={operators} />} />
                    <Route path="/machines" element={<MachinesIndex machines={machines} />} />
                    <Route path="/machines/new" element={<MachineForm />} />
                    <Route path="/trackings" element={<TrackingsIndex trackings={trackings} />} />
                    <Route path="/trackings/new" element={<TrackingForm machine={machine} user={user} />} />
                    <Route path="/clients" element={<ClientsIndex clients={clients} />} />
                </Routes>
            </BrowserRouter>
        </Box>
    );
}

App.propTypes = {
    currentUser: PropTypes.object,
    currentUserIsAdmin: PropTypes.bool,
    machines: PropTypes.array,
    machine: PropTypes.array,
    trackings: PropTypes.array,
    user: PropTypes.object,
    operators: PropTypes.array,
    clients: PropTypes.array,
};

export default App;

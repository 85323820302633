import React, { useState } from "react";
import { TextField, Button, FormControl, InputLabel, Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

function TrackingForm({ user, machine }) {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        const tracking = {
            initial_hourmeter: data.get("tracking[initial_hourmeter]"),
            final_hourmeter: data.get("tracking[final_hourmeter]"),
            fuel: data.get("tracking[fuel]"),
            initial_odometer: data.get("tracking[initial_odometer]"),
            standby_hours: data.get("tracking[standby_hours]"),
            image: data.get("tracking[image]"),
            machine_id: machine.id,
            operator_id: user.id
        }

        fetch("/trackings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ tracking }),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Success:", data);
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    };

    return (

        <ThemeProvider theme={theme}>
            <Box component="form" onSubmit={handleSubmit} sx={{ margin: "auto", maxWidth: "100%" }}>
                <TextField
                    label="Horometro Inicial"
                    name="tracking[initial_hourmeter]"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Horometro Final"
                    name="tracking[final_hourmeter]"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="ACPM"
                    name="tracking[fuel]"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Initial Odometer"
                    name="tracking[initial_odometer]"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Standby Hours"
                    name="tracking[standby_hours]"
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="image">Seleccionar imagen</InputLabel>
                    <input
                        type="file"
                        id="image"
                        name="tracinkg[image]"
                    />
                </FormControl>
                <Button type="submit" variant="contained" color="primary">
                    Crear registro
                </Button>
            </Box>
        </ThemeProvider>
    );
};

TrackingForm.propTypes = {
    user: PropTypes.object,
    machine: PropTypes.object,
};

export default TrackingForm;

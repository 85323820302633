import Box from "@mui/material/Box";
import React from "react";
import PropTypes from "prop-types";
import SearchBar from "../../components/SearchBar";
import MachinesTable from "./MachinesTable";
import Filters from "../../components/Filters";
import { Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import theme from "../../../packs/mui_theme";
import ModalWithButton from "../../components/ModalWithButton";
import MachineForm from "./MachineForm";
import { OutlinedMachineIcon, StateIcon, MoneyIcon } from "../../components/Icons";

function MachinesIndex({ machines }) {
    const filters = [
        { label: "Estado", options: ["En contrato", "En mantenimiento", "Disponible"], icon: StateIcon },
        { label: "Precio/hora", options: ["vacio"], icon: MoneyIcon },
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: "100vh" }}>
                <Grid item xs={12} sx={{ paddingX: 4 }} md={12} component={Paper} elevation={6} square>
                    <Box sx={{ backgroundColor: "#F3F4F4", borderRadius: "10px" }}>
                        <Grid container
                            spacing={2}
                            columns={16}
                            sx={{
                                p: "10px",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="headlineLarge"
                                color="#414648"
                                sx={{
                                    fontWeight: "700",
                                }}
                            >
                                Maquinarias
                            </Typography>

                            <ModalWithButton
                                buttonMessage="Agregar máquina"
                                modalTitle="Agregar máquina"
                                modalIcon={OutlinedMachineIcon}
                                modalContent={MachineForm}
                                formId="machine-form"
                                buttonTitle="Agregar máquina"
                            />
                        </Grid>

                        <Grid container
                            sx={{
                                p: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <SearchBar
                                placeholderText="Buscar máquina"
                            />

                            <Filters
                                filters={filters}
                            />
                        </Grid>

                        <MachinesTable machines={machines} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

MachinesIndex.propTypes = {
    machines: PropTypes.array,
};

export default MachinesIndex;
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Copyright from "../../components/Copyright";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { PasswordInput } from "../../../packs/mui_theme";
import React from "react";
import Slider from "../../components/Slider";
import theme from "../../../packs/mui_theme";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";

function SignUp({ signInUrl, helperText }) {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: "100vh" }}>
                <Grid item xs={12} sx={{ paddingX: 4 }} md={6} component={Paper} elevation={6} square>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>

                        <Box
                            sx={{
                                width: "167px",
                                height: "134px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            Logo de producto
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                maxWidth: "375px",
                                margin: "auto",
                                gap: "19px"
                            }}
                        >

                            <Typography variant="title1" color="surface.black"
                                sx={{
                                    fontWeight: "bold",
                                    width: "335px",
                                    height: "36px"
                                }}>
                                Crea tu cuenta
                            </Typography>

                            <Typography variant="body2" color="neutral.700"
                                sx={{
                                    fontWeight: "medium",
                                    width: "335px",
                                    height: "18px"
                                }}>
                                Ingresa los siguientes datos para crear tu cuenta.
                            </Typography>

                            <Box sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            label="Nombre"
                                            name="sign_up[first_name]"
                                            autoComplete="name"
                                            variant="filled"
                                            size="lg"
                                            error={Boolean(helperText)}
                                            helperText={helperText}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            label="Correo electrónico"
                                            name="sign_up[email]"
                                            autoComplete="email"
                                            variant="filled"
                                            size="lg"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            fullWidth
                                            id="password"
                                            name="sign_up[password]"
                                            label="Contraseña"
                                            type="password"
                                            autoComplete="current-password"
                                            variant="filled"
                                            size="lg"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            fullWidth
                                            id="password"
                                            name="sign_up[password_confirmation]"
                                            label="Confirma tu contraseña"
                                            type="password"
                                            autoComplete="current-password"
                                            variant="filled"
                                            size="lg"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            height: "48px",
                                            textTransform: "none",
                                            backgroundColor: "primary.600"
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                LineWeight: "14px",
                                                fontSize: "14px"
                                            }}
                                            color="surfaces.white">
                                            Crear cuenta
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Divider>
                                    <Typography variant="dividertext1" color="#8D8D8D">
                                        O
                                    </Typography>
                                </Divider>

                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={5}
                                >
                                    <Grid item>
                                        <Typography variant="body2"
                                            color="neutral.700"
                                            sx={{
                                                fontWeight: "medium"
                                            }}>
                                            ¿Ya tienes una cuenta?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="neutral"
                                            href={signInUrl}
                                            variant="outlined"
                                            sx={{
                                                textTransform: "none",
                                                p: "8px 10px 8px 10px",
                                                height: "32px",
                                                borderRadius: "8px"
                                            }}>

                                            <Typography
                                                sx={{
                                                    fontWeight: "600",
                                                    LineWeight: "12px",
                                                    fontSize: "12px"
                                                }}
                                                color="neutral.950">
                                                Ingresa aquí
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Grid>
                <Slider />
            </Grid>
        </ThemeProvider>
    );

}

SignUp.propTypes = {
    signInUrl: PropTypes.string,
    helperText: PropTypes.string,
};

export default SignUp;

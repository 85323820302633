import Box from "@mui/material/Box";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Copyright() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    if (isDesktop) {
        return (
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "34px" }}>
                <Typography variant="paragraph1" color="neutral.main" >
                    {"© "}
                    {new Date().getFullYear()}
                    {" GPM | Todos los derechos reservados."}
                </Typography>

                <Typography variant="paragraph1" color="neutral.main">
                    Terminos y condiciones
                </Typography>
            </Box>
        );
    }

    return (
        <Typography variant="paragraph1"
            color="neutral.main"
            align="center"
            sx={{ justifyContent: "center", alignItems: "center", display: "flex", marginBottom: "34px" }}
        >
            {"© "}
            {new Date().getFullYear()}
            {" GPM | Todos los derechos reservados."}
        </Typography>
    );
}

export default Copyright;
import React, { useState } from "react";
import FilterSelect from "./FilterSelect";

const Filters = ({ filters }) => {
    const [selectedOptions, setSelectedOptions] = useState({});

    const handleFilterChange = (event, label) => {
        setSelectedOptions({
            ...selectedOptions,
            [label]: event.target.value,
        });
    };

    return (
        <>
            {filters.map((filter, index) => (
                <FilterSelect
                    key={index}
                    label={filter.label}
                    options={filter.options}
                    value={selectedOptions[filter.label] || []}
                    onChange={(event) => handleFilterChange(event, filter.label)}
                    icon={filter.icon}
                />
            ))}
        </>
    );
};

export default Filters;
import Box from "@mui/material/Box";
import React, { useState } from "react";
import theme from "../../../packs/mui_theme";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Alerts from "../../components/Alerts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const InputLabelWithStyles = ({ inputLabelText }) => {
    return (
        <InputLabel>
            <Typography variant="labelMedium"
                sx={{
                    color: "neutral.10",
                    fontWeight: "600",
                    lineHeight: "12px"
                }}
            >
                {inputLabelText}
            </Typography>
        </InputLabel>
    )
};

function ClientForm() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        document_type: "",
        identification_number: "",
        email: "",
        phone_number: "",
        address: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("/api/clients", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ client: formData }),
            });

            if (response.ok) {
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                const errorData = await response.json();
                console.log("Error:", errorData);
            }
        } catch (error) {
            console.log("Network Error:", error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit} id="client-form">
                <Box>
                    <Grid container
                        sx={{
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Nombre completo"
                            />
                            <TextField
                                id="name"
                                name="name"
                                variant="filled"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Tipo documento"
                            />
                            <Select
                                id="document_type"
                                name="document_type"
                                variant="filled"
                                value={formData.document_type}
                                onChange={handleChange}
                                fullWidth
                            >
                                <MenuItem value="identification_card">Cedula</MenuItem>
                                <MenuItem value="nit">NIT</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Numero de documento"
                            />
                            <TextField
                                id="identification_number"
                                name="identification_number"
                                variant="filled"
                                value={formData.identification_number}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Correo electronico"
                            />
                            <TextField
                                id="email"
                                name="email"
                                variant="filled"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Telefono"
                            />
                            <TextField
                                id="phone_number"
                                name="phone_number"
                                variant="filled"
                                value={formData.phone_number}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item>
                            <InputLabelWithStyles
                                inputLabelText="Direccion"
                            />
                            <TextField
                                id="address"
                                name="address"
                                variant="filled"
                                value={formData.address}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Alerts
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message="El cliente fue creado con éxito."
                severity="success"
            />
        </ThemeProvider >
    );
}

ClientForm.propTypes = {};

export default ClientForm;
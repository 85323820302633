import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MachineForm from "./MachineForm";
import DynamicTable from "../../components/DynamicTable";
import { OutlinedMachineIcon } from "../../components/Icons";
import MachinesEmptyState from "../../../../assets/images/machines_empty_state.png";

function MachinesTable({ machines }) {
    const [machinesList, setMachinesList] = useState(machines || []);
    const columns = [
        { label: "Nombre máquina", field: "name" },
        { label: "Estado", field: "machine_status" },
        { label: "Horómetro inicial", field: "hourmeter" },
        // TODO: Añadir horometro final en machines
        { label: "Horómetro final", field: "kilometers_difference" },
        { label: "Consumo promedio", field: "acpm_average" },
        { label: "Costo/Hora", field: "basic_hourly_price" },
    ];

    useEffect(() => {
        if (machinesList.length === 0) {
            fetch("/api/machines", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    setMachinesList(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [machinesList]);

    return (
        <>
            <DynamicTable columns={columns}
                data={machinesList}
                emptyStateIcon={MachinesEmptyState}
                emptyStatePrimaryText="Aún no has agregado maquinarias"
                emptyStateSecondaryText="Aquí encontrarás todas las maquinas que hayas agregado."
                emptyStateButtonMessage="Agregar máquina"
                emptyStateModalTitle="Agregar máquina"
                emptyStateModalIcon={OutlinedMachineIcon}
                emptyStateModalContent={MachineForm}
                emptyStateFormId="machine-form"
                emptyStateButtonTitle="Agregar máquina"
            />
        </>
    );
}

MachinesTable.propTypes = {
    machines: PropTypes.array,
};

export default MachinesTable;
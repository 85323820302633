import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Copyright from "../../components/Copyright";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { PasswordInput } from "../../../packs/mui_theme";
import React from "react";
import Slider from "../../components/Slider";
import theme from "../../../packs/mui_theme";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function ResponsiveTypography({ text, color, variantDesktop, variantMobile, fontWeight }) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Typography variant={isDesktop ? variantDesktop : variantMobile} color={color}
            sx={{
                fontWeight: { fontWeight }
            }}
        >
            {text}
        </Typography>
    );
}

function SignIn({ signUpUrl, forgotPassword, helperText }) {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container component="main" sx={{ height: "100vh" }}>
                <Grid item xs={12} sx={{ paddingX: 4 }} md={6} component={Paper} elevation={6} square>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
                        <Box
                            sx={{
                                width: "167px",
                                height: "134px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            Logo de producto
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                maxWidth: "375px",
                                margin: "auto",
                                padding: "20px",
                                gap: "19px"
                            }}
                        >

                            <ResponsiveTypography text={"Ingresa a tu cuenta"}
                                color={"surface.black"}
                                variantDesktop={"h5"}
                                variantMobile={"title1"}
                                fontWeight={"bold"}
                                sx={{
                                    width: "335px",
                                    height: "36px"
                                }}
                            />

                            <ResponsiveTypography text={"Ingresa tus datos para acceder a tu cuenta."}
                                color={"neutral.700"}
                                variantDesktop={"body2"}
                                variantMobile={"body2"}
                                fontWeight={"medium"}
                                sx={{
                                    width: "335px",
                                    height: "18px"
                                }}
                            />

                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="email"
                                            label="Correo electrónico"
                                            name="user[email]"
                                            variant="filled"
                                            autoComplete="email"
                                            fullWidth
                                            size="lg"
                                            error={Boolean(helperText)}
                                            helperText={helperText}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PasswordInput
                                            id="password"
                                            label="Contraseña"
                                            name="user[password]"
                                            variant="filled"
                                            autoComplete="current-password"
                                            type="password"
                                            size="lg"
                                            error={Boolean(helperText)}
                                            helperText={helperText}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item xs>
                                                <FormControlLabel
                                                    control={<Checkbox name="user[remember_me]" />}
                                                    label={<Typography variant="body2" color="neutral.main" sx={{ fontWeight: "medium" }}>
                                                        No cerrar sesión
                                                    </Typography>}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Link href={forgotPassword}
                                                    variant="body2"
                                                    color="primary.500"
                                                    underline="none"
                                                    sx={{
                                                        fontWeight: "medium"
                                                    }}>
                                                    ¿Olvidaste tu contraseña?
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            height: "48px",
                                            textTransform: "none",
                                            backgroundColor: "primary.600"
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "600",
                                                LineWeight: "14px",
                                                fontSize: "14px"
                                            }}
                                            color="surfaces.white">
                                            Iniciar sesión
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Divider>
                                    <Typography variant="dividertext1" color="#8D8D8D">
                                        O
                                    </Typography>
                                </Divider>

                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Typography variant="body2"
                                            color="neutral.700"
                                            sx={{
                                                fontWeight: "medium"
                                            }}>
                                            ¿No tienes una cuenta?
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="neutral"
                                            href={signUpUrl}
                                            variant="outlined"
                                            sx={{
                                                textTransform: "none",
                                                p: "8px 10px 8px 10px",
                                                height: "32px",
                                                borderRadius: "8px"
                                            }}>

                                            <Typography
                                                sx={{
                                                    fontWeight: "600",
                                                    LineWeight: "12px",
                                                    fontSize: "12px",
                                                }}
                                                color="neutral.950">
                                                Créala aquí
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Grid>
                <Slider />
            </Grid>
        </ThemeProvider >
    );
}

SignIn.propTypes = {
    signUpUrl: PropTypes.string,
    forgotPassword: PropTypes.string,
    helperText: PropTypes.string,
};

export default SignIn;
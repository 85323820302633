import React from "react";
import PropTypes from "prop-types";
import ModalWithButton from "./ModalWithButton";
import { Typography, Box, Grid } from "@mui/material";

function EmptyState({ imageUrl, primaryText, secondaryText, buttonMessage,
    modalTitle, modalIcon, modalContent, formId, buttonTitle }) {

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        }}>
            <Grid>
                <Box
                    sx={{
                        width: "120px",
                        height: "100px",
                        backgroundImage: `url(${imageUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "auto",
                        backgroundPosition: "center",
                    }}
                />
                <Typography>
                    {primaryText}
                </Typography>
                <Typography>
                    {secondaryText}
                </Typography>

                <ModalWithButton
                    buttonMessage={buttonMessage}
                    modalTitle={modalTitle}
                    modalIcon={modalIcon}
                    modalContent={modalContent}
                    formId={formId}
                    buttonTitle={buttonTitle}
                />
            </Grid>
        </Box>
    );
}

EmptyState.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
    buttonMessage: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalIcon: PropTypes.elementType.isRequired,
    modalContent: PropTypes.elementType.isRequired,
    formId: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
};

export default EmptyState;
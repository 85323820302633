import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import theme from "../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import SignOutButton from "../components/SignOutButton";
import ProfilePhoto from "../../../assets/images/profile_photo.png";
import {
    TrackingIcon, AgreementIcon, ClientIcon, OperatorsIcon,
    OutlinedMachineIcon, FilledMachineIcon, SetUpIcon, LogoutIcon,
    NotificationIcon
} from "../components/Icons";
import { Box, Button, Typography } from "@mui/material";

const sideBarOptions = [
    { label: "Tracking", path: "/trackings", icon: <TrackingIcon /> },
    { label: "Contratos", path: "/contratos", icon: <AgreementIcon />, adminOnly: true },
    { label: "Clientes", path: "/clients", icon: <ClientIcon />, adminOnly: true },
    { label: "Maquinarias", path: "/machines", icon: <OutlinedMachineIcon />, adminOnly: true },
    { label: "Operarios", path: "/operators", icon: <OperatorsIcon />, adminOnly: true },
];

function SideBar({ isAdmin, currentUser }) {
    const location = useLocation();
    const [selectedPath, setSelectedPath] = useState(location.pathname);

    const handleListItemClick = (path) => {
        setSelectedPath(path);
    };

    return (
        <ThemeProvider theme={theme}>
            <Drawer
                sx={{
                    width: 260,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 260,
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Box>
                    <Grid container sx={{ padding: "10px", alignItems: "center" }}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: "#144BE1", borderRadius: "15px" }}>
                                <FilledMachineIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={
                            <Typography variant="headlineSmall"
                                color="#414648"
                                sx={{
                                    fontWeight: "800",
                                    lineHeight: "24px",
                                    letterSpacing: "0.33px",
                                }}
                            >
                                GPM
                            </Typography>
                        } />

                        <Button variant="outlined"
                            startIcon={<NotificationIcon />}
                        />
                    </Grid>
                    <Divider />
                </Box>

                <List sx={{ marginBottom: "auto" }}>
                    {sideBarOptions
                        .filter(option => !option.adminOnly || isAdmin)
                        .map((option, index) => (
                            <ListItem key={index} disablePadding sx={{
                                p: "8px"
                            }}>
                                <ListItemButton
                                    component={Link}
                                    to={option.path}
                                    selected={selectedPath === option.path}
                                    onClick={() => handleListItemClick(option.path)}
                                    sx={{
                                        borderRadius: "12px",
                                        backgroundColor: selectedPath === option.path ? "#FFF4ED !important" : "inherit",
                                        "&:hover": {
                                            backgroundColor: "#FFF4ED !important",
                                        },
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{
                                            color: selectedPath === option.path ? "#FFFFFF" : "#61676A",
                                            backgroundColor: selectedPath === option.path ? "#FE4A11" : "#F3F4F4"
                                        }}>
                                            {option.icon}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={
                                        <Typography variant="labelLarge"
                                            color={selectedPath === option.path ? "#FE4A11" : "#61676A"}
                                            sx={{
                                                fontWeight: selectedPath === option.path ? "600" : "500",
                                                lineHeight: "24px",
                                                letterSpacing: "-0.3px",
                                            }}
                                        >
                                            {option.label}
                                        </Typography>} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                </List>

                <Box>
                    <Divider />
                    <Grid container sx={{ alignItems: "center", p: "10px" }}>
                        <ListItemAvatar>
                            <Avatar src={`${ProfilePhoto}`} sx={{ borderRadius: "15px" }} />
                        </ListItemAvatar>
                        <ListItemText primary={
                            <Typography variant="labelLarge"
                                color="#414648"
                                sx={{
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                }}
                            >
                                {`${currentUser.first_name} ${currentUser.last_name}`}
                            </Typography>
                        }
                            secondary={`${currentUser.role}`} />
                    </Grid>
                    <Grid container
                        sx={{
                            p: "10px",
                            margin: "auto",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <Button
                            variant="outlined"
                            startIcon={<SetUpIcon />}
                        >
                            Ajustes
                        </Button>
                        <SignOutButton />
                    </Grid>
                </Box>
            </Drawer>
        </ThemeProvider>
    );
}

SideBar.propTypes = {
    currentUser: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
};

export default SideBar;
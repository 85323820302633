import React from "react";
import theme from "../../packs/mui_theme";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Select, MenuItem, FormControl, InputLabel, Checkbox, Box, Divider, Typography } from "@mui/material";

const FilterSelect = ({ label, options, value, onChange, icon: LabelIcon }) => {
    const handleClearFilter = () => {
        onChange({ target: { value: [] } });
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControl sx={{ width: 200 }} margin="normal">
                    <InputLabel>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {LabelIcon && <LabelIcon sx={{ marginRight: "8px" }} />}
                            <Typography variant="body1">{label}</Typography>
                        </Box>
                    </InputLabel>
                    <Select
                        multiple
                        value={value}
                        onChange={onChange}
                        renderValue={(selected) => {
                            if (selected.length === 0) return <em>{label}</em>;
                            return selected.join(', ');
                        }}
                        label={label}
                    >
                        <MenuItem sx={{ display: "none" }} disabled value="">
                            <em>{label}</em>
                        </MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                                <Checkbox checked={value.indexOf(option) > -1} />
                            </MenuItem>
                        ))}

                        <Divider />
                        <MenuItem onClick={handleClearFilter}>
                            Limpiar filtro
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
};

FilterSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.elementType.isRequired,
};

export default FilterSelect;
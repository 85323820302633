import ReactOnRails from 'react-on-rails';

import SignIn from '../bundles/SignIn/components/SignIn';
import SignUp from '../bundles/SignUp/components/SignUp';
import TrackingsIndex from '../bundles/Tracking/components/TrackingsIndex';
import TrackingForm from '../bundles/Tracking/components/TrackingForm';
import MachinesIndex from '../bundles/Machines/components/MachinesIndex';
import MachineForm from '../bundles/Machines/components/MachineForm';
import OperatorsIndex from '../bundles/Operators/components/OperatorsIndex';
import OperatorForm from '../bundles/Operators/components/OperatorForm';
import ClientsIndex from '../bundles/Clients/components/ClientsIndex';
import App from "../app";

ReactOnRails.register({
    SignIn,
    SignUp,
    ClientsIndex,
    TrackingsIndex,
    TrackingForm,
    MachinesIndex,
    MachineForm,
    OperatorsIndex,
    OperatorForm,
    App,
});

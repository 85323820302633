import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import gpmSlide1 from "../../../assets/images/gpm_slide1.png";
import IconSlider from "../../../assets/images/icon.png";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function SliderComponent() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {[0, 1, 2, 3, 4].map((index) => (
                    <div
                        key={index}
                        style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            borderColor: "white",
                            borderWidth: "1px",
                            borderStyle: "ridge",
                            background: index === currentIndex ? "white" : "transparent",
                            margin: "0 5px",
                            cursor: "pointer",
                        }}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </div>
        </div>
    );
}

function BackgroundSlider() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    if (isDesktop) {

        return (
            <ThemeProvider theme={theme}>
                <Grid
                    item
                    xs={false}
                    md={6}
                    sx={{
                        position: "relative",
                        "&::before": {
                            content: "''",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            background: `url(${gpmSlide1})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "bottom",
                        },
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            position: "absolute",
                            top: "39%",
                            left: "50%",
                            maxWidth: "460px",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                            gap: "24px"
                        }}
                    >
                        <Box
                            sx={{
                                width: "105px",
                                height: "80px",
                                backgroundImage: `url(${IconSlider})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "auto",
                                backgroundPosition: "center",
                            }}
                        />

                        <Typography variant="h5"
                            color="#FFFFFF"
                            sx={{
                                fontWeight: "700",
                            }}>
                            Gestiona toda tu maquinaria pesada
                        </Typography>

                        <Typography variant="body2"
                            color="#FFFFFF"
                            sx={{
                                fontWeight: "500",
                            }}>
                            Organiza tu maquinaria pesada, recibe alertas de mantenimiento y accede a
                            historiales de servicio fácilmente con nuestra app.
                        </Typography>
                        <SliderComponent />
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

export default BackgroundSlider;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ClientForm from "./ClientForm";
import DynamicTable from "../../components/DynamicTable";
import { ClientIcon } from "../../components/Icons";
import ClientsEmptyState from "../../../../assets/images/clients_empty_state.png";

function MachinesTable({ clients }) {
    const [clientsList, setClientList] = useState(clients || []);
    const columns = [
        { label: "Nombre", field: "name" },
        { label: "Tipo de documento", field: "document_type" },
        { label: "Documento", field: "identification_number" },
        { label: "Correo electronico", field: "email" },
        { label: "Telefono", field: "phone_number" },
    ];

    useEffect(() => {
        if (clientsList.length === 0) {
            fetch("/api/clients", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {
                    setClientList(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [clientsList]);

    return (
        <>
            <DynamicTable columns={columns}
                data={clientsList}
                emptyStateIcon={ClientsEmptyState}
                emptyStatePrimaryText="Aún no has agregado clientes"
                emptyStateSecondaryText="Aquí encontrarás todos los clientes que hayas agregado."
                emptyStateButtonMessage="Agregar cliente"
                emptyStateModalTitle="Agregar cliente"
                emptyStateModalIcon={ClientIcon}
                emptyStateModalContent={ClientForm}
                emptyStateFormId="client-form"
                emptyStateButtonTitle="Agregar cliente"
            />
        </>
    );
}

MachinesTable.propTypes = {
    clients: PropTypes.array,
};

export default MachinesTable;
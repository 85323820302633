import React from "react";
import { createTheme } from "@mui/material/styles";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const PasswordInput = ({ password, handlePassword, label, name, helperText, error }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePassword}
            label={label}
            name={name}
            variant="filled"
            helperText={helperText}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            sx={{
                                color: "#4F4F4F",
                            }}
                        >
                            {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            fullWidth
        />
    );
};

const theme = createTheme({
    typography: {
        fontFamily: "Inter, sans-serif",
        button: {
            textTransform: "none",
        },
        displayLarge: {
            fontSize: "58px",
            lineHeight: "64px",
        },
        displayMedium: {
            fontSize: "44px",
            lineHeight: "52px",
        },
        displaySmall: {
            fontSize: "36px",
            lineHeight: "44px",
        },
        headlineLarge: {
            fontSize: "32px",
            lineHeight: "40px",
        },
        headlineMedium: {
            fontSize: "28px",
            lineHeight: "36px",
        },
        headlineSmall: {
            fontSize: "24px",
            lineHeight: "32px",
        },
        titleLarge: {
            fontSize: "20px",
            lineHeight: "28px",
        },
        titleMedium: {
            fontSize: "28px",
            lineHeight: "24px",
        },
        titleSmall: {
            fontSize: "14px",
            lineHeight: "20px",
        },
        bodyLarge: {
            fontSize: "16px",
            lineHeight: "24px",
        },
        bodyMedium: {
            fontSize: "13px",
            lineHeight: "20px",
        },
        bodySmall: {
            fontSize: "12px",
            lineHeight: "16px",
        },
        labelLarge: {
            fontSize: "14px",
            lineHeight: "20px",
        },
        labelMedium: {
            fontSize: "12px",
            lineHeight: "16px",
        },
        labelSmall: {
            fontSize: "11px",
            lineHeight: "16px",
        },
    },
    palette: {
        primary: {
            main: "#68C92C",
            100: "#ffffff",
            95: "#f0f4ff",
            90: "#dce9fa",
            80: "#c0d4f7",
            70: "#81a9f6",
            60: "#5f8ef6",
            50: "#4866db",
            40: "#3758ca",
            30: "#2b43b0",
            20: "#2c2a82",
            10: "#1a2970",
            5: "#141f50",
            0: "#000000",
        },
        secondary: {
            main: "#289FEE",
            100: "#ffffff",
            95: "#FFF4ED",
            90: "#FFC8A8",
            80: "#FFC8A8",
            70: "#FFA271",
            60: "#FF7A38",
            50: "#FE4A11",
            40: "#EF3007",
            30: "#C61F08",
            20: "#9D1B0F",
            10: "#7E1910",
            5: "#440906",
            0: "#000000",
        },
        tertiary: {
            main: "#113E63",
        },
        neutral: {
            main: "#788286",
            100: "#ffffff",
            95: "#F9FAFA",
            90: "#F3F4F4",
            80: "#E6E7E8",
            70: "#D1D5D6",
            60: "#B5BBBD",
            50: "#B5BBBD",
            40: "#778082",
            30: "#61676A",
            20: "#515557",
            10: "#414648",
            5: "#DDFBDD",
            0: "#000000",
        },
        error: {
            main: "#FFEDEA",
            95: "#FFDAD6",
            90: "#F3F4F4",
            80: "#FFB4AB",
            70: "#FF897D",
            60: "#FF5449",
            50: "#E31A12",
            40: "#B81F1E",
            30: "#93000A",
            20: "#690005",
            10: "#410002",
            5: "#410002",
            0: "#410002",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                },
                contained: {
                    backgroundColor: "#144BE1",
                    color: "#FFFF",
                    "&:hover": {
                        backgroundColor: "#3281ff",
                    },
                },
                outlined: {
                    borderWidth: "1px",
                    borderColor: "#E6E7E8",
                    color: "#414648",
                    "&:hover": {
                        borderColor: "#3281ff",
                        color: "#3281ff",
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Filled
                    "& .MuiFilledInput-root": {
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #D1D1D1",
                        borderRadius: "16px",
                        height: "54px",

                        "&.MuiFilledInput-root::before": {
                            borderBottom: "0px",
                        },
                        "&.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "0px",
                        },
                        "&.MuiFilledInput-root::after": {
                            borderBottom: "0px",
                        },
                        "&.Mui-focused": {
                            backgroundColor: "transparent",
                            borderColor: "#296AF5",
                            borderWidth: "2px",
                        },
                    },

                    // Outlined
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "16px",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "1px",
                        },
                        "&.Mui-focused": {
                            borderColor: "#59a5ff",
                            backgroundColor: "#E8F1F8",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "2px",
                            },
                        },
                        "&:hover:not(.Mui-focused)": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc",
                            },

                            "&:hover": {
                                backgroundColor: "#D1D1D1",
                            },
                        },
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    backgroundColor: "#F3F4F4",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "16px",
                    borderBottom: "1px solid #E6E7E8",
                    color: "#61676A",
                },
                body: {
                    color: "#414648",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "13px",
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    "&.Mui-selected": {
                        color: "#FFFFFF",
                        border: "1px solid #144BE1",
                        backgroundColor: "#144BE1"
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#59A5FF",
                        border: "1px solid #59A5FF",
                    },
                }
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        color: "#DB3A33",
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        color: "#DB3A33",
                    },
                    "&.Mui-focused": {
                        color: "#296AF5",
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 375,
            sm: 750,
            md: 1024,
            lg: 1440,
        },
    },
});

export default theme;
export { PasswordInput };
